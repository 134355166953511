.asset-select{
  width: 15rem;
  font-size: 2rem;
  margin: 1rem;
  padding: 1rem;
  border-radius: 64px;
  background-color: #000000;

}

.type-select{
  width: 18rem;
  font-size: 2rem;
  margin: 1rem;
  padding: 1rem;
  border-radius: 64px;
  background-color: #000000;

}

.asset-select > .active, .type-select > .active{
  color: #FFFFFF;
}

.asset-select > .disabled, .type-select > .disabled{
  color: #747474;
}

.orders-wrapper{
  display: block;
  table-layout: auto;
  width: 100%;
  border: 0px solid transparent;
  margin: 1rem;
}

.orders-wrapper > thead{
  width: 100%;
}

.orders-wrapper > thead > tr{
  width: 100%;

}

.orders-wrapper > thead > tr > th{
  width: 200px;
  text-align: center;
  padding: 1rem;
}

.orders-wrapper > tbody{
  display: block;
  overflow-y: scroll;
  max-height: 600px;
  width: 100%;
}

.orders-wrapper > tbody > tr{
  height: 100%;
  background: black;
}

.orders-wrapper > tbody > tr > td{
  text-align: left;
  font-size: 1.25rem;
  height: 2rem;
  color: white;
  width: 200px;
  padding: 1rem;
}


.orderbooks-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
}

caption{
  font-size: 3rem;
}

.tables-wrapper{
  display: flex;
  flex-direction: row;

}

.orderArrow{
  width: 10% !important;
}

.btn-clear{
  background: transparent;
  border: 0px solid transparent;
}

.form-wrapper{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin:2rem;
}

.order-form-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border: 2px solid black;
  border-radius: 12px;
  width: 100%;
}

.order-form-wrapper > h2 {
  font-size: 2.5rem;
}

.order-form-wrapper > label , .order-form-wrapper > label > input {
  margin: 1rem;
  font-size: 1.25rem;
}

.order-form-wrapper > button.submit{
  width: 12rem;
  height: 4rem;
  border-radius: 64px;
  color: white;
  background-color: black;
  font-size: 1.5rem;

}

.trades-table-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.trades-table-wrapper > table{
  display: block;
  table-layout: auto;
  width: 1200px;
  border: 0px solid transparent;
  margin: 2rem;
}

.trades-table-wrapper > table > thead > tr > th , .trades-table-wrapper > table >  tbody > tr > td{
  width: 400px;
  border-bottom: 1px solid black;
  padding: 1rem 0rem;
  font-size: 1.25rem;
}

.trades-table-wrapper > table > tbody > tr{
  width: 100%;
}

.form-label{
  width: 100%;
}
