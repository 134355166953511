.header{
  display: flex;
  flex-direction: row;
  background-color: black;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  

}

.header > .title{
  width: 33%;
  
}